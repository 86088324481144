import './App.css';

function App() {
  // let login = false;
  return (
    <>
      <div className="App">
        <header className="App-header">
          <div className="Header-logo">
          </div>
          <div className="Header-actions">        
            <button className="Actions-link">Dashboard</button>
            <button className="Actions-link" >Sign In</button>
          </div>
        </header>
        <section className="App-body">
        </section>
        <footer className="App-footer">
        </footer>
      </div >
    </>
  );
}

export default App;